<template>
  <div class="container">
    <div class="top" id="bloc-0">
      <voice-header></voice-header>
    </div>
    
    <div class="main api-intro-page">
      <!-- Hero Section -->
      <div class="hero-section">
        <div class="bubbles">
          <div class="bubble" v-for="n in 15" :key="n"></div>
        </div>
        
        <div class="hero-content">
          <h1 :data-text="$t('api_intro.heroTitle')">{{ $t('api_intro.heroTitle') }}</h1>
          <p class="hero-subtitle">{{ $t('api_intro.heroSubtitle') }}</p>
          <div class="cta-buttons">
            <router-link :to="'/' + $route.params.locale + '/pricing'" class="btn-primary">{{ $t('api_intro.viewPricing') }}</router-link>
          </div>
        </div>
      </div>

      <!-- Features Section -->
      <section class="about-section features-section">
        <div class="feature-row">
          <div class="feature-text">
            <h3>{{ $t('api_intro.featureEngine.title') }}</h3>
            <p>{{ $t('api_intro.featureEngine.desc') }}</p>
            <ul>
              <li v-for="(point, index) in $t('api_intro.featureEngine.points')" :key="index">{{ point }}</li>
            </ul>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/feature-engine.png" alt="High Performance Engine">
          </div>
        </div>
        
        <div class="feature-row reverse">
          <div class="feature-text">
            <h3>{{ $t('api_intro.featureLanguage.title') }}</h3>
            <p>{{ $t('api_intro.featureLanguage.desc') }}</p>
            <ul>
              <li v-for="(point, index) in $t('api_intro.featureLanguage.points')" :key="index">{{ point }}</li>
            </ul>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/feature-languages.png" alt="Language Support">
          </div>
        </div>

        <div class="feature-row">
          <div class="feature-text">
            <h3>{{ $t('api_intro.featureSocial.title') }}</h3>
            <p>{{ $t('api_intro.featureSocial.desc') }}</p>
            <ul>
              <li v-for="(point, index) in $t('api_intro.featureSocial.points')" :key="index">{{ point }}</li>
            </ul>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/usecase-video.png" alt="Social Media Content">
          </div>
        </div>
        
        <div class="feature-row reverse">
          <div class="feature-text">
            <h3>{{ $t('api_intro.featureLearning.title') }}</h3>
            <p>{{ $t('api_intro.featureLearning.desc') }}</p>
            <ul>
              <li v-for="(point, index) in $t('api_intro.featureLearning.points')" :key="index">{{ point }}</li>
            </ul>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/usecase-education.png" alt="E-Learning Solutions">
          </div>
        </div>

        <div class="feature-row">
          <div class="feature-text">
            <h3>{{ $t('api_intro.featureService.title') }}</h3>
            <p>{{ $t('api_intro.featureService.desc') }}</p>
            <ul>
              <li v-for="(point, index) in $t('api_intro.featureService.points')" :key="index">{{ point }}</li>
            </ul>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/usecase-service.png" alt="AI Customer Service">
          </div>
        </div>
      </section>

      <!-- Integration Steps Section -->
      <section class="about-section integration-steps-section">
        <h2>{{ $t('api_intro.integration.title') }}</h2>
        <div class="steps-container">
          <div class="step-item" v-for="(step, index) in $t('api_intro.integration.steps')" :key="index">
            <div class="step-number">{{ index + 1 }}</div>
            <h4>{{ step.title }}</h4>
            <p>{{ step.desc }}</p>
          </div>
        </div>
      </section>
    </div>

    <div class="footer">
      <voice-footer></voice-footer>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import VoiceHeader from '../components/VoiceHeader.vue'

const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooter.vue'))

export default {
  name: 'ApiIntro',
  components: {
    VoiceHeader,
    VoiceFooter
  },
  head() {
    return {
      title: this.$t('api_intro.headTitle'),
      keywords: this.$t('api_intro.headKeywords'),
      description: this.$t('api_intro.headDescription'),
      link: [
        { 
          rel: 'stylesheet', 
          href: '/ssr/css/second_style.css',
          id: 'second-style',
          media: 'all'
        }
      ]
    }
  }
}
</script>

<style scoped>
@import '/ssr/css/api_intro.css';

.container {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
}

.main {
  width: 100%;
}

.code-sample {
  width: 100%;
  max-width: 800px;
  margin: 30px auto;
  background: #1e1e1e;
  border-radius: 12px;
  padding: 20px;
  overflow-x: auto;
}

.code-sample code {
  color: #fff;
  font-family: 'Fira Code', monospace;
}
</style>
